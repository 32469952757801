@use "styles/shared" as *;

.UsernameSearch {
  /*! keep */
  :global {
    height: 100%;
    h3 {
      font-family: "Rethink Sans", sans-serif;
    }
    .description {
      max-width: 22rem;
    }
    .soc-card {
      width: 10.26094rem;
      height: 6.25rem;
      align-items: center;
      justify-content: center;

      @screen sm {
        width: 12.26094rem;
        height: 8.25rem;
      }

      @screen md {
        width: 14.26094rem;
        height: 10.25rem;
      }
      @screen lg {
        width: 10.26094rem;
        height: 8.25rem;
      }

      .logo {
        svg {
          width: 1.71988rem;
          height: 1.71988rem;
          @screen sm {
            width: 2.57813rem;
            height: 2.57813rem;
          }
        }
      }
      .title {
        font-family: "Rethink Sans", sans-serif;
        font-size: 0.66313rem;
        @screen sm {
          font-size: 0.875rem;
        }
        @screen md {
          font-size: 1rem;
        }
        @screen lg {
          font-size: 0.875rem;
        }
        @screen xl {
          font-size: 1rem;
        }
      }
      .status {
        font-size: 0.58019rem;
        @screen sm {
          font-size: 0.675rem;
        }
        @screen md {
          font-size: 0.875rem;
        }
        @screen lg {
          font-size: 0.675rem;
        }
        @screen xl {
          font-size: 0.875rem;
        }
      }
      &.instagram {
        width: 12.4375rem;
        height: 8rem;
        align-items: center;
        justify-content: center;

        @screen sm {
          width: 14.26094rem;
          height: 10.25rem;
        }

        @screen md {
          width: 16.4375rem;
          height: 12rem;
        }
        @screen lg {
          width: 12.4375rem;
          height: 10rem;
        }
        .logo {
          svg {
            width: 2.16813rem;
            height: 2.16806rem;
            @screen sm {
              width: 3.25rem;
              height: 3.24988rem;
            }
          }
        }
      }
    }
  }
}

.UsernameSearch__default {
  /*! keep */
  :global {
  }
}
