@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500&display=swap");
@import "./bootstrap/index.scss";
@import "./shared.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.search-fixed-pad {
  padding-top: 130px;
}
