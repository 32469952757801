@use "styles/shared" as *;

.Search {
  /*! keep */
  :global {
  }
}

.Search__default {
  /*! keep */
  :global {
  }
}