@use "styles/shared" as *;

.Badge {
  /*! keep */
  :global {
    font-family: "DM Sans", sans-serif;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5625rem;
    font-size: .82rem;
    font-weight: 500;
  }
}

.Badge__default {
  /*! keep */
  :global {
  }
}

.Badge__outline {
  /*! keep */
  :global {
    border: 1px solid #00c667;
    color: #2a2a2a;
    background-color: transparent !important;
  }
}
