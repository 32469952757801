.HeadCaption {
  /*! keep */
  text-align: center;
  h1 {
    font-family: "Rethink Sans", sans-serif;
    max-width: 44.1875rem;
    font-style: normal;
    font-weight: 800;
    @screen sm {
      font-size: 4rem;
    }
  }
  h3 {
    max-width: 19.25rem;
    @screen sm {
      max-width: unset;
    }
  }
}
