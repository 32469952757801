@use "styles/shared" as *;

.AvailablityStatus {
  /*! keep */
  :global {
  }
}

.AvailablityStatus__default {
  /*! keep */
  :global {
  }
}
