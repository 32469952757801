@use "styles/shared" as *;

.Domains {
  /*! keep */
  :global {
  }
}

.Domains__default {
  /*! keep */
  :global {
  }
}
