@use "styles/shared" as *;

.SocialCard {
  /*! keep */
  :global {
    justify-content: center;
    align-items: center;
    background-color: theme("colors.nc-gray.200");
    display: flex;
    border-radius: 0.5rem;
    .title {
      font-family: "Rethink Sans", sans-serif;
    }
  }
}

.SocialCard__default {
  /*! keep */
  :global {
  }
}

.SocialCardLoader {
  /*! keep */
  :global {
    justify-content: center;
    align-items: center;
    background-color: theme("colors.nc-gray.200");
    display: flex;
    flex: none;
    flex-direction: column;
    border-radius: 0.5rem;
    height: 10rem;
    text-align: center;
    .logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      // background: #ddd;
    }
    .status-label {
      display: inline-block;
      width: 120px;
      height: 20px;
      // background: #ddd;
    }
  }
}
