@use "styles/shared" as *;

.ExtIconLink {
  /*! keep */
  :global {
    svg {
      display: inline;
    }
  }
}

.ExtIconLink__default {
  /*! keep */
  :global {
  }
}
