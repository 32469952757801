@use "styles/shared" as *;

.DomainAccordion {

  /*! keep */
  :global {
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.5rem;

    .head {
      border-radius: 0.5rem;

      .title {
        font-family: "Rethink Sans", sans-serif;
      }

      button {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: theme("colors.nc-gray.200");
        outline: none;
        box-shadow: none;
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        box-shadow: none;

        &:hover {
          background-color: #ececec;
        }

        &.collapsed {
          border-bottom-left-radius: 0.5rem !important;
          border-bottom-right-radius: 0.5rem !important;
        }

        &::after {
          margin-left: 0.3rem;
        }

        @screen sm {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }

      &.unavailable {
        button {
          &::after {
            display: none;
          }
        }

        .btn-buy-now {
          display: none;
        }
      }
    }

    .body {
      border: 1px solid theme("colors.nc-gray.300");
      border-top: none;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      padding: 0;

      .accordion-row {
        display: flex;
        flex-direction: column;

        .accordion-col {
          padding: 2rem 1.5rem;

          @screen lg {
            padding: 2rem 3.5rem;
            width: 50%;
          }
        }

        @screen lg {
          flex-direction: row;
        }
      }
    }
  }
}

.DomainAccordion__default {

  /*! keep */
  :global {
    border-radius: 0.5rem;
  }
}

.DomainAccordionLoader {

  /*! keep */
  :global {
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: theme("colors.nc-gray.200");
    border-radius: 0.5rem;
    width: 100%;
    height: 3.75rem;
    margin-bottom: 1rem;

    .title {
      width: 200px;
      height: 20px;
    }

    .status-label {
      width: 140px;
      height: 20px;
    }
  }
}