@use "styles/shared" as *;

.SearchBar {
  /*! keep */
  :global {
  }
  &:global(.search-fixed) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    border-bottom: 1px solid theme("colors.nc-gray.300");
  }
  &:global(.py-2rem) {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

.SearchBar__default {
  /*! keep */
  :global {
  }
}
