@use "styles/shared" as *;

.SectionTitle {
  /*! keep */
  :global {
    font-family: "Rethink Sans", sans-serif;
  }
}

.SectionTitle__default {
  /*! keep */
  :global {
  }
}
