.shimmer {
  animation-duration: 2.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #dbdbdb;
  background: linear-gradient(to right, #dbdbdb 8%, #f6f6f6 18%, #dbdbdb 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -1200% 0;
  }

  100% {
    background-position: 1200% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.nc-text-grad {
  background: -webkit-linear-gradient(360deg, #705af8, #9b8bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-title {
  font-family: "Rethink Sans", sans-serif;
}

.underline-text {
  border: 0px solid #705af8;
  border-bottom-width: 1px;
}

p a {
  text-decoration: underline;
}