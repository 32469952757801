@use "styles/shared" as *;

.Social {

  /*! keep */
  :global {
    .slider {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      gap: 1.25rem;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .slide {
        flex: 0 0 auto;
        width: 15.7rem;

        @media (max-width: 576px) {
          width: 12.37rem;
        }

        height: 10rem;
      }

      @screen sm {
        padding-left: 0;
        padding-right: 0;
      }

      @screen 2xl {
        flex-wrap: wrap;
      }
    }
  }
}

.Social__default {

  /*! keep */
  :global {}
}