@use "styles/shared" as *;

.Logo {
  /*! keep */
  :global {
    font-family: "Rethink Sans", sans-serif;
  }
}

.Logo__default {
  /*! keep */
  :global {
  }
}
