.ConsistentOnlineIdentity {
  :global {
    .page-title {
      font-family: "Rethink Sans", sans-serif;
    }

    .underline-text {
      border: 0px solid #705af8;
      border-bottom-width: 1px;
    }
  }
}