@use "styles/shared" as *;

.DomainSearch {
  /*! keep */
  :global {
    height: 100%;
    h3 {
      font-family: "Rethink Sans", sans-serif;
    }
    .description {
      max-width: 22rem;
    }
    .domain-mock-list {
      .lft {
        width: 85%;
      }
      font-size: 0.58019rem;
      .title {
        font-family: "Rethink Sans", sans-serif;
        font-size: 0.66313rem;
        @screen sm {
          font-size: 0.875rem;
        }
        @screen md {
          font-size: 1rem;
        }
        @screen lg {
          font-size: 0.875rem;
        }
        @screen xl {
          font-size: 1rem;
        }
      }
      .btn-buy-now {
        // svg {
        //   width: 0.33156rem;
        //   height: 0.58663rem;
        //   @screen sm {
        //     width: 0.5rem;
        //     height: 0.88463rem;
        //   }
        // }
      }
      @screen sm {
        font-size: 0.675rem;
      }
      @screen md {
        font-size: 0.875rem;
      }
      @screen lg {
        font-size: 0.675rem;
      }
      @screen xl {
        font-size: 0.875rem;
      }
    }
  }
}

.DomainSearch__default {
  /*! keep */
  :global {
  }
}
