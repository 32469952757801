//theme-color-variables

$primary: #705AF8;
// $secondary: $gray-600 !default;
// $success: $green !default;
// $info: $cyan !default;
// $warning: $yellow !default;
// $danger: $red !default;
// $light: $gray-100 !default;
// $dark: $gray-900 !default;

//breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
);

// container-max-widths
$container-max-widths: (
  sm: 460px,
  md: 720px,
  lg: 960px,
  xl: 1024px,
  xxl: 1070px,
);
