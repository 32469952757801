@use "styles/shared" as *;

.FiverBanner {
  /*! keep */
  :global {
  }
}

.FiverBanner__default {
  /*! keep */
  :global {
  }
}