@use "styles/shared" as *;

.Top10Panel {
  /*! keep */
  :global {
  }
}

.Top10Panel__default {
  /*! keep */
  :global {
    h2 {
      font-family: "Rethink Sans", sans-serif;
      font-weight: 800;
    }
  }
}